import '../styles/index.scss';

//import $ from "jquery/dist/jquery.slim";
//import "bootstrap/dist/js/bootstrap.bundle";
import "bootstrap/js/dist/tab";
//import SelectorEngine from "bootstrap/js/dist/dom/selector-engine";
import "bootstrap/js/dist/dropdown";
import "bootstrap/js/dist/collapse";
//import Offcanvas from "bootstrap/js/dist/offcanvas";
//import "bootstrap/js/dist/tooltip";
//import Tooltip from 'bootstrap/js/dist/tooltip';
import { Modal, Offcanvas, Tooltip, Dropdown } from "bootstrap";
import SelectorEngine from "bootstrap/js/src/dom/selector-engine";

// Initialise tooltips
const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl));

// Initialise modals
const modalTriggerList = document.querySelectorAll('[data-bs-toggle="modal"]');
const modalList = [...modalTriggerList].map(modalTriggerEl => new Modal(modalTriggerEl.getAttribute('href') || modalTriggerEl.getAttribute('data-bs-target')));

// Initialise offcanvas menu
const primaryNavOffCanvasEle = document.getElementById('primaryNavOffCanvas');
const primaryNavOffCanvas = new Offcanvas(primaryNavOffCanvasEle);
const primaryNavOffCanvasMenuBtnEle = document.getElementById('primary-nav-offcanvas-menu-btn');
const primaryNavOffCanvasMenuCloseBtnEle = document.getElementById('primary-nav-offcanvas-close-btn');

primaryNavOffCanvasEle.addEventListener('show.bs.offcanvas', event => {
    primaryNavOffCanvasMenuBtnEle.classList.add('d-none');
    primaryNavOffCanvasMenuCloseBtnEle.classList.remove('d-none');
});

primaryNavOffCanvasEle.addEventListener('hide.bs.offcanvas', event => {
    primaryNavOffCanvasMenuBtnEle.classList.remove('d-none');
    primaryNavOffCanvasMenuCloseBtnEle.classList.add('d-none');
});


// Dropdown on hover
/*const dropdownHoverTriggerList = document.querySelectorAll('[data-bs-hover="dropdown"]');
const dropdownHoverList = [...dropdownHoverTriggerList].map(dropdownHoverTriggerEle => {
    const dropdownMenu = dropdownHoverTriggerEle.parentElement.querySelector('.dropdown-menu');

    //if (!('ontouchstart' in document.documentElement)) {
        dropdownMenu.addEventListener('mouseover', event => {
            event.target.classList.add('show');
        });
        dropdownMenu.addEventListener('mouseout', event => {
            event.target.classList.remove('show');
        });
        dropdownHoverTriggerEle.addEventListener('mouseover', event => {
            event.target.parentElement.querySelector('.dropdown-menu').classList.add('show');
        });
        dropdownHoverTriggerEle.addEventListener('mouseout', event => {
            event.target.parentElement.querySelector('.dropdown-menu').classList.remove('show');
        });
    //}
});*/

const primaryNavEle = SelectorEngine.findOne('.primary-nav .dropdown-toggle', document.body);
primaryNavEle.addEventListener('click', event => {
    Dropdown.getOrCreateInstance(event.target).toggle();

    // Using the following to detect whether we're on a small screen, if so disable event
    const svg_image = SelectorEngine.findOne('svg', event.target);
    if (window.getComputedStyle(svg_image).display !== 'none') {
        event.preventDefault();
    }
});
